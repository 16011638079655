import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

function BlipContent({ blip }) {
  return (
    <Columns.Column className="is-8 content">
      <h3 className="">{blip.category.title}</h3>
      <h1 className="is-top-marginless">{blip.title}</h1>
      {blip.radarstatus ? (
        <span
          className={`tag is-medium is-rounded is-outlined ${blip.radarstatus.title.toLowerCase()}`}
          title={blip.radarstatus.description}
        >
          {blip.radarstatus ? blip.radarstatus.title : ''}
        </span>
      ) : (
        <span />
      )}
      <br />
      <br />
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: blip.bodyNode.childMarkdownRemark.html,
        }}
      />
    </Columns.Column>
  )
}
BlipContent.propTypes = {
  blip: PropTypes.object,
}

export default BlipContent
