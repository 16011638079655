import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

function OurOpinion({ blip }) {
  return (
    <Columns.Column className="is-3 is-offset-1 content">
      <h4>Our opinion</h4>
      <p>
        <span
          className={`tag is-medium is-rounded is-outlined ${blip.radarstatus.title.toLowerCase()}`}
          title={blip.radarstatus.description}
        >
          {blip.radarstatus ? blip.radarstatus.title : ''}
        </span>
        &nbsp; {blip.radarstatus.description}
      </p>
    </Columns.Column>
  )
}
OurOpinion.propTypes = {
  blip: PropTypes.object,
}
export default OurOpinion
