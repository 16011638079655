import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

function BlipNav({ blip }) {
  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <Link to="/mobile-technology-radar">Mobile Technology Radar</Link>
        </li>
        <li>
          <Link
            to={`/mobile-technology-radar/${blip.category.slug}`}
            aria-current="page"
          >
            {blip.category.title}
          </Link>
        </li>
        <li className="is-active">
          <a href="#" aria-current="page">
            {blip.title}
          </a>
        </li>
      </ul>
    </nav>
  )
}
BlipNav.propTypes = {
  blip: PropTypes.object,
}
export default BlipNav
