/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

// Meta Component
// import Meta from 'components/Meta'

// Blip Route
import Blip from 'routes/templates/blip'

// Gatsby SEO Head
export function Head({ data: { blip } }) {
  return (
    <>
      <title>
        {`${blip.title} | ${blip.category.title} | Mobile Technology Radar | Pocketworks`}
      </title>
      <meta
        name="title"
        content={`${blip.title} | ${blip.category.title} | Mobile Technology Radar | Pocketworks`}
      />
      <meta
        name="description"
        content={blip.bodyNode.childMarkdownRemark.excerpt}
      />
      <meta
        property="og:title"
        content={`${blip.title} | ${blip.category.title} | Mobile Technology Radar | Pocketworks`}
      />
      <meta
        property="og:description"
        content={blip.bodyNode.childMarkdownRemark.excerpt}
      />
      <meta property="og:type" content="article" />
      <meta
        property="og:image"
        content={
          'https://www.datocms-assets.com/20955/1583318289-mobile-technology-radar.jpg?w=1000&amp;fit=max&amp;fm=jpg'
        }
      />
      <meta
        name="twitter:title"
        content={`${blip.title} | ${blip.category.title} | Mobile Technology Radar | Pocketworks`}
      />
      <meta
        name="twitter:description"
        content={blip.bodyNode.childMarkdownRemark.excerpt}
      />
      <meta name="twitter:site" content="@thepocketworks" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content={
          'https://www.datocms-assets.com/20955/1583318289-mobile-technology-radar.jpg?w=1000&amp;fit=max&amp;fm=jpg'
        }
      />
    </>
  )
}

export default Blip

export const query = graphql`
  query BlipQuery($slug: String!) {
    blip: datoCmsRadarBlip(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      fontAwesomeId
      meta {
        updatedAt(formatString: "MMMM D, YYYY")
      }
      bodyNode {
        childMarkdownRemark {
          html
          timeToRead
          excerpt
        }
      }
      radarstatus {
        title
        description
      }
      category {
        title
        slug
        description
      }
    }
  }
`
