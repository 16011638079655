import PropTypes from 'prop-types'
import React from 'react'
import { Section, Columns } from 'react-bulma-components'

// Componnets
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Componnets
import BlipNav from './components/blip-nav'
import OurOpinion from './components/our-opinion'
import BlipContent from './components/blip-content'

function Blip({ data: { blip } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <Section>
        <Columns className="is-centered">
          <Columns.Column className="is-9">
            <BlipNav blip={blip} />
            <Columns>
              <BlipContent blip={blip} />
              <OurOpinion blip={blip} />
            </Columns>
          </Columns.Column>
        </Columns>
      </Section>
    </Layout>
  )
}
Blip.propTypes = {
  data: PropTypes.object,
}

export default Blip
